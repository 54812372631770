/* Global */

.copied-message {
  display: none;
}

@media (min-width: 768px){
	.col-md-3,.col-md-6,.col-md-9 {
			float: left;
	}
}

.special-background {
    background-attachment: fixed;
    background-repeat: repeat-x;
		background-size: cover;
    height: 100vh;
	  background-position: bottom;
}

.form-control::placeholder {
    font-size: 13px;
}

.nav a { color: #072f86 !important; }

.form-sub-options {
    display: flex;
    gap: 20px;
    margin-top: 10px;
}

.form-sub-options label {
    font-size: 12px;
}

h1 {
    font-size: 24px !important;
    text-transform: uppercase;
    color: #072f86 !important;
}

.btn-global {
	background-color: #072f86 !important;
	border-color: #072f86 !important;
}

.App {
	display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.minHeight200 {
	min-height: 200px;
}

.flex {
	display: flex;	
}

.align-content-end {
	justify-content: flex-end;
}

.main,.boxed {
	flex: 1;
}

h1,h2,h3 {
	font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
}

.btn-square {
    border-radius: 0 !important; /* Removes the border-radius */
}

.mt-20 {
    margin-top: 20px !important;
}

.main {
	margin-top: 1rem;
	margin-bottom: 8rem;
}

.wrapper {
    max-width: 1180px;
}

.boxed {
	margin-top: 3rem !important;
	margin-bottom: 8rem !important;
	width: 500px;
	margin: 0 auto;
}

@media screen and (max-width: 640px) {
		.boxed {
			width: 100%;
			padding: 0 30px;
		}
}


.links a {
	text-decoration: none;
	color: #222;
}

.uiInterface {

	padding-top: 4rem;
	padding-bottom: 4rem; 
}

button.close {
    margin-left: 10px;
}


/* Form */

.invalid-feedback {
    text-align: left;
}

.text-left {
	text-align: left;
}

/* Tooltip */

input, select {
    color: #666666 !important;
}

.form-label {
    margin-bottom: 0.5rem;
    font-weight: 500;
}

.tooltip-container {
	position: relative;
	display: inline-block;
}
	
.tooltip {
	visibility: hidden;
	width: 120px;
	background-color: black;
	color: white;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	bottom: 100%;
	left: 50%;
	margin-left: -60px;
	opacity: 0;
	transition: opacity 0.3s;
}
	
.iconToolTip:hover .tooltip {
	visibility: visible;
	opacity: 1;
}

/* Base styles */


/* Studentdetails */

.studentDetails table.personal {
	margin-top: 20px;
}


/* Footer */

footer {
	padding: 1em;
	background-color: #f0f0f0;
	text-align: center;
  }

  .active {
	font-weight: bold;
  }
  
.uiInterface {
	background-color: rgb(233 233 233); 
	padding-top: 4rem;
	padding-bottom: 4rem; 
}

.pagination {
	justify-content: center;
}

.table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}